import React from 'react';
import './App.css';
import Rhinopithecus from './pages/Rhinopithecus';
import Man from './pages/Man';
import Skull from './pages/skull';
import Wolf from './pages/Wolf';
import Jet from './pages/Jet';
function App() {
  return (
    <div className="App">
     {/*<Man/>*/ } 
      {/*<Skull/>*/}
      {/*<Rhinopithecus />*/}
     {/* <Kidney/>*/}
     <Wolf/>
    </div>
  );
}

export default App;
