import React, { useEffect, useRef, useState } from 'react';
import * as BABYLON from '@babylonjs/core';
import '@babylonjs/loaders';
import { SceneLoader } from '@babylonjs/core/Loading/sceneLoader';
import { AreaChart,Area,LineChart, Line, RadarChart,PolarGrid,PolarAngleAxis,PolarRadiusAxis,Radar,RadialBar,XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar, ReferenceLine,ResponsiveContainer, Brush,PieChart, Pie, Cell, ScatterChart, Scatter, ZAxis,LabelList } from 'recharts';

const getPath = (x, y, width, height) => {
  return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
  ${x + width / 2}, ${y}
  C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
  Z`;
}
const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

const Wolf = () => {
  const canvasRef = useRef(null);
  const [skeleton, setSkeleton] = useState(null);
  const [animationGroups, setAnimationGroups] = useState([]);
  const [currentAnimation, setCurrentAnimation] = useState('Static');
  const [vitalSigns, setVitalSigns] = useState({
    heartRate: 75,
    bloodPressure: { systolic: 120, diastolic: 80 },
    bodyTemperature: 38.0,
    respiratoryRate: 18,
    oxygenSaturation: 98,
    ecg: [] // Add ECG data
  });

  const [lastPlayedAnimation, setLastPlayedAnimation] = useState(null);

// ... existing code ...

// Use lastPlayedAnimation and setLastPlayedAnimation as needed
  // Generate dynamic data for charts
  const generateChartData = (initialValue, range, count) => {
    const data = [];
    let currentValue = initialValue;
    for (let i = 0; i < count; i++) {
      const time = new Date(Date.now() - (count - 1 - i) * 1000).toISOString().substr(11, 8);
      currentValue += (Math.random() - 0.5) * range;
      data.push({ time, value: Math.round(currentValue * 10) / 10 });
    }
    return data;
  };

  const [heartRateData, setHeartRateData] = useState(generateChartData(75, 10, 30));

  const [weeklySleepEfficiency, setWeeklySleepEfficiency] = useState(() => {
    const data = [];
    for (let day = 6; day >= 0; day--) {
      data.push({
        day: day,
        sleepEfficiency: Math.round(70 + Math.random() * 25) // Random efficiency between 70% and 95%
      });
    }
    return data;
  });
  const [dailySleepDuration, setDailySleepDuration] = useState(() => {
    const data = [];
    for (let day = 6; day >= 0; day--) {
      data.push({
        day: day,
        sleepDuration: 5 + Math.random() * 4 // Random sleep duration between 5 and 9 hours
      });
    }
    return data;
  });
  const [remSleepDuration, setRemSleepDuration] = useState(() => {
    const data = [];
    for (let day = 6; day >= 0; day--) {
      data.push({
        day: day,
        remDuration: 0.9 + Math.random() * 0.6 // Random REM sleep duration between 0.9 and 1.5 hours
      });
    }
    return data;
  });

  const [deepSleepDuration, setDeepSleepDuration] = useState(() => {
    const data = [];
    for (let day = 6; day >= 0; day--) {
      data.push({
        day: day,
        deepDuration: 1.2 + Math.random() * 0.8 // Random deep sleep duration between 1.2 and 2 hours
      });
    }
    return data;
  });

  const [lightSleepDuration, setLightSleepDuration] = useState(() => {
    const data = [];
    for (let day = 6; day >= 0; day--) {
      const totalSleep = dailySleepDuration[6 - day].sleepDuration;
      const remSleep = remSleepDuration[6 - day].remDuration;
      const deepSleep = deepSleepDuration[6 - day].deepDuration;
      data.push({
        day: day,
        lightDuration: totalSleep - remSleep - deepSleep // Light sleep is the remaining duration
      });
    }
    return data;
  });
  const [bloodPressureData, setBloodPressureData] = useState(
    generateChartData(120, 8, 30).map((item, index) => ({
      ...item,
      systolic: item.value,
      diastolic: Math.round(item.value * 2/3)
    }))
  );
  const [weeklyHourlyHeartRate, setWeeklyHourlyHeartRate] = useState(() => {
    const data = [];
    for (let day = 6; day >= 0; day--) {
      for (let hour = 0; hour < 24; hour++) {
        data.push({
          day: day,
          hour: hour,
          avgBPM: Math.round(60 + Math.random() * 40) // Random BPM between 60 and 100
        });
      }
    }
    return data;
  });

  const [weeklyHourlyBloodPressure, setWeeklyHourlyBloodPressure] = useState(() => {
    const data = [];
    for (let day = 6; day >= 0; day--) {
      for (let hour = 0; hour < 24; hour++) {
        const systolic = Math.round(110 + Math.random() * 30); // Random systolic between 110 and 140
        const diastolic = Math.round(systolic * 2/3); // Diastolic is roughly 2/3 of systolic
        data.push({
          day: day,
          hour: hour,
          avgSystolic: systolic,
          avgDiastolic: diastolic
        });
      }
    }
    return data;
  });

  const [weeklyHourlyBodyTemperature, setWeeklyHourlyBodyTemperature] = useState(() => {
    const data = [];
    for (let day = 6; day >= 0; day--) {
      for (let hour = 0; hour < 24; hour++) {
        data.push({
          day: day,
          hour: hour,
          avgTemperature: (36.5 + Math.random() * 1.5).toFixed(1) // Random temperature between 36.5 and 38.0
        });
      }
    }
    return data;
  });

  const [weeklyHourlyRespiratoryRate, setWeeklyHourlyRespiratoryRate] = useState(() => {
    const data = [];
    for (let day = 6; day >= 0; day--) {
      for (let hour = 0; hour < 24; hour++) {
        data.push({
          day: day,
          hour: hour,
          avgRespiratoryRate: Math.round(12 + Math.random() * 8) // Random respiratory rate between 12 and 20
        });
      }
    }
    return data;
  });

  const [weeklyHourlyOxygenSaturation, setWeeklyHourlyOxygenSaturation] = useState(() => {
    const data = [];
    for (let day = 6; day >= 0; day--) {
      for (let hour = 0; hour < 24; hour++) {
        data.push({
          day: day,
          hour: hour,
          avgOxygenSaturation: Math.round(95 + Math.random() * 5) // Random oxygen saturation between 95 and 100
        });
      }
    }
    return data;
  });



  
  const [bodyTemperatureData, setBodyTemperatureData] = useState(generateChartData(38.0, 0.5, 30));
  const [respiratoryRateData, setRespiratoryRateData] = useState(generateChartData(18, 4, 30));
  const [oxygenSaturationData, setOxygenSaturationData] = useState(generateChartData(98, 2, 30));
  const [ecgData, setEcgData] = useState([]); // Add ECG data state

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('vitalSigns');
  const [selectedTab, setSelectedTab] = useState('ecg');

  // Generate statistical data
  const generateStatData = (data) => {
    const values = data.map(item => item.value);
    return {
      min: Math.min(...values),
      max: Math.max(...values),
      avg: Math.round(values.reduce((a, b) => a + b, 0) / values.length * 10) / 10
    };
  };

  // Generate ECG data
  const generateEcgData = () => {
    const data = [];
    for (let i = 0; i < 100; i++) {
      if (i % 20 === 0) {
        data.push({ time: i, value: Math.random() * 0.5 + 0.5 }); // P wave
      } else if (i % 20 === 5) {
        data.push({ time: i, value: Math.random() * -1 - 0.5 }); // Q wave
      } else if (i % 20 === 6) {
        data.push({ time: i, value: Math.random() * 2 + 1 }); // R wave
      } else if (i % 20 === 7) {
        data.push({ time: i, value: Math.random() * -0.5 - 0.25 }); // S wave
      } else if (i % 20 === 12) {
        data.push({ time: i, value: Math.random() * 0.5 + 0.25 }); // T wave
      } else {
        data.push({ time: i, value: Math.random() * 0.1 }); // Baseline
      }
    }
    return data;
  };
  // Generate EEG data
  const generateEegData = () => {
    const data = [];
    for (let i = 0; i < 500; i++) {
      // Simulate different brain wave frequencies
      const delta = Math.sin(i * 0.1) * 20; // 0.5-4 Hz
      const theta = Math.sin(i * 0.2) * 15; // 4-8 Hz
      const alpha = Math.sin(i * 0.4) * 10; // 8-13 Hz
      const beta = Math.sin(i * 0.8) * 5;   // 13-30 Hz
      const gamma = Math.sin(i * 1.6) * 2;  // 30-100 Hz

      // Combine waves and add some noise
      const value = delta + theta + alpha + beta + gamma + (Math.random() - 0.5) * 10;

      data.push({ time: i, value: value });
    }
    return data;
  };
  // Generate EOG data
  const generateEogData = () => {
    const data = [];
    for (let i = 0; i < 500; i++) {
      // Simulate eye movement patterns
      const slowEyeMovement = Math.sin(i * 0.05) * 10; // Slow eye movements
      const rapidEyeMovement = (i % 50 < 5) ? Math.sin(i * 0.5) * 20 : 0; // Occasional rapid eye movements
      const blinks = (i % 100 < 2) ? -30 : 0; // Occasional blinks

      // Combine patterns and add some noise
      const value = slowEyeMovement + rapidEyeMovement + blinks + (Math.random() - 0.5) * 5;

      data.push({ time: i, value: value });
    }
    return data;
  };

  // Generate EMG data
  const generateEmgData = () => {
    const data = [];
    for (let i = 0; i < 500; i++) {
      // Simulate muscle activity
      const baselineActivity = Math.random() * 2; // Low-level baseline activity
      const muscleContraction = (i % 100 < 20) ? Math.random() * 10 : 0; // Occasional muscle contractions

      // Combine activity and add some noise
      const value = baselineActivity + muscleContraction + (Math.random() - 0.5) * 1;

      data.push({ time: i, value: value });
    }
    return data;
  };

  // Generate Sleep Spindles data
  const [sleepSpindlesData, setSleepSpindlesData] = useState(() => {
    const data = [];
    for (let i = 0; i < 500; i++) {
      let value = 0;
      // Simulate sleep spindles (12-14 Hz oscillations)
      if (i % 100 < 20) { // Spindles occur in bursts
        value = Math.sin(i * 0.8) * 10 + (Math.random() - 0.5) * 2;
      } else {
        value = (Math.random() - 0.5) * 2; // Background EEG
      }
      data.push({ time: i, value: value });
    }
    return data;
  });

  // Generate K-Complex data
  const [kComplexData, setKComplexData] = useState(() => {
    const data = [];
    for (let i = 0; i < 500; i++) {
      let value = 0;
      // Simulate K-complexes (sudden negative then positive peak)
      if (i % 100 === 0) { // K-complexes occur periodically
        value = -20;
      } else if (i % 100 === 1) {
        value = 20;
      } else {
        value = (Math.random() - 0.5) * 5; // Background EEG
      }
      data.push({ time: i, value: value });
    }
    return data;
  });
  // Generate Slow Wave data
  const [slowWaveData, setSlowWaveData] = useState(() => {
    const data = [];
    for (let i = 0; i < 500; i++) {
      // Simulate slow waves (0.5-2 Hz)
      const value = Math.sin(i * 0.02) * 20 + (Math.random() - 0.5) * 5;
      data.push({ time: i, value: value });
    }
    return data;
  });

  // Generate Sawtooth Wave data
  const [sawtoothWaveData, setSawtoothWaveData] = useState(() => {
    const data = [];
    for (let i = 0; i < 500; i++) {
      // Simulate sawtooth waves (characteristic of REM sleep)
      let value = ((i % 50) / 50) * 20 - 10; // Sawtooth pattern
      value += (Math.random() - 0.5) * 2; // Add some noise
      data.push({ time: i, value: value });
    }
    return data;
  });
  // Generate skin lesion data
  const [skinLesionData, setSkinLesionData] = useState(() => {
    const areas = ['Head', 'Neck', 'Back', 'Chest', 'Legs', 'Tail'];
    return areas.map(area => ({
      area,
      hairLoss: Math.round(Math.random() * 18), // Percentage of hair loss (0-18%)
      inflammation: Math.round(Math.random() * 10), // Inflammation score (0-10)
      scabs: Math.round(Math.random() * 10), // Scab severity (0-10)
      ulcers: Math.round(Math.random() * 5), // Number of ulcers
    }));
  });

  // Function to update skin lesion data
  const updateSkinLesionData = () => {
    setSkinLesionData(prevData => 
      prevData.map(item => ({
        ...item,
        hairLoss: Math.max(0, Math.min(100, item.hairLoss + (Math.random() - 0.5) * 5)),
        inflammation: Math.max(0, Math.min(10, item.inflammation + (Math.random() - 0.5) * 0.5)),
        scabs: Math.max(0, Math.min(10, item.scabs + (Math.random() - 0.5) * 0.5)),
        ulcers: Math.max(0, Math.min(5, item.ulcers + (Math.random() - 0.5) * 0.2)),
      }))
    );
  };

  // Generate parasite infestation data
  const [parasiteData, setParasiteData] = useState([
    {
      parasite: "Ticks",
      count: Math.round(Math.random() * 50),
      severity: Math.round(Math.random() * 10),
      location: "Ears",
      description: "Small, blood-sucking arachnids that can transmit diseases."
    },
    {
      parasite: "Fleas",
      count: Math.round(Math.random() * 100),
      severity: Math.round(Math.random() * 10),
      location: "Fur",
      description: "Tiny, wingless insects that feed on blood and cause itching."
    },
    {
      parasite: "Mites",
      count: Math.round(Math.random() * 200),
      severity: Math.round(Math.random() * 10),
      location: "Skin",
      description: "Microscopic arthropods that can cause mange and skin irritation."
    },
    {
      parasite: "Lice",
      count: Math.round(Math.random() * 75),
      severity: Math.round(Math.random() * 10),
      location: "Fur and skin",
      description: "Small, wingless insects that live on skin and hair, causing itching."
    }
  ]);

  const generatePredictionData = (initialValue, range, days) => {
    const data = [];
    let currentValue = initialValue;
    for (let i = 0; i < days; i++) {
      currentValue += (Math.random() - 0.5) * range;
      data.push({
        day: i,
        value: Math.round(currentValue * 10) / 10,
      });
    }
    return data;
  };

  const [predictionData] = useState(() => ({
    heartRate: generatePredictionData(vitalSigns.heartRate, 5, 7),
    systolic: generatePredictionData(vitalSigns.bloodPressure.systolic, 8, 7),
    diastolic: generatePredictionData(vitalSigns.bloodPressure.diastolic, 6, 7),
    temperature: generatePredictionData(vitalSigns.bodyTemperature, 0.5, 7),
    respiratoryRate: generatePredictionData(vitalSigns.respiratoryRate, 2, 7),
    oxygenSaturation: generatePredictionData(vitalSigns.oxygenSaturation, 1, 7),
    sleep: {
      totalSleepTime: generatePredictionData(480, 60, 7), // 初始值8小时（480分钟），每天变化范围1小时
      sleepEfficiency: generatePredictionData(85, 5, 7), // 初始值85%，每天变化范围5%
      lightSleep: generatePredictionData(240, 30, 7), // 初始值4小时（240分钟），每天变化范围30分钟
      remSleep: generatePredictionData(120, 20, 7), // 初始值2小时（120分钟），每天变化范围20分钟
      deepSleep: generatePredictionData(120, 20, 7) // 初始值2小时（120分钟），每天变化范围20分钟
    }
  }));
  // Function to update parasite data
  const updateParasiteData = () => {
    setParasiteData(prevData => 
      prevData.map(item => ({
        ...item,
        count: Math.max(0, item.count + Math.round((Math.random() - 0.5) * 10)),
        severity: Math.max(0, Math.min(10, item.severity + (Math.random() - 0.5) * 0.5))
      }))
    );
  };

  
  // Add this to your useEffect for periodic updates
  useEffect(() => {
    const interval = setInterval(() => {
      updateSkinLesionData();
    }, 5000); // Update every 5 seconds

    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    const updateVitalSigns = () => {
      setVitalSigns(prevSigns => {
        let newSigns = { ...prevSigns };
        
        switch (currentAnimation) {
          case 'Static':
            // Minimal changes
            newSigns.heartRate = Math.round(prevSigns.heartRate + (Math.random() - 0.5) * 1);
            newSigns.bloodPressure = {
              systolic: Math.round(prevSigns.bloodPressure.systolic + (Math.random() - 0.5) * 1),
              diastolic: Math.round(prevSigns.bloodPressure.diastolic + (Math.random() - 0.5) * 0.5)
            };
            newSigns.bodyTemperature = Math.round((prevSigns.bodyTemperature + (Math.random() - 0.5) * 0.05) * 10) / 10;
            newSigns.respiratoryRate = Math.round(prevSigns.respiratoryRate + (Math.random() - 0.5) * 0.5);
            newSigns.oxygenSaturation = Math.min(100, Math.max(95, Math.round(prevSigns.oxygenSaturation + (Math.random() - 0.5) * 0.5)));
            break;
          case 'Walk':
            // Slight increase in heart rate and respiratory rate
            newSigns.heartRate = Math.round(prevSigns.heartRate + (Math.random() * 2));
            newSigns.bloodPressure = {
              systolic: Math.round(prevSigns.bloodPressure.systolic + (Math.random() * 1.5)),
              diastolic: Math.round(prevSigns.bloodPressure.diastolic + (Math.random() * 1))
            };
            newSigns.bodyTemperature = Math.round((prevSigns.bodyTemperature + (Math.random() * 0.1)) * 10) / 10;
            newSigns.respiratoryRate = Math.round(prevSigns.respiratoryRate + (Math.random() * 1.5));
            newSigns.oxygenSaturation = Math.min(100, Math.max(95, Math.round(prevSigns.oxygenSaturation + (Math.random() - 0.5) * 1)));
            break;
          case 'Run':
            // Significant increase in heart rate, respiratory rate, and slight decrease in oxygen saturation
            newSigns.heartRate = Math.round(prevSigns.heartRate + (Math.random() * 8));
            newSigns.bloodPressure = {
              systolic: Math.round(prevSigns.bloodPressure.systolic + (Math.random() * 3)),
              diastolic: Math.round(prevSigns.bloodPressure.diastolic + (Math.random() * 2))
            };
            newSigns.bodyTemperature = Math.round((prevSigns.bodyTemperature + (Math.random() * 0.2)) * 10) / 10;
            newSigns.respiratoryRate = Math.round(prevSigns.respiratoryRate + (Math.random() * 3));
            newSigns.oxygenSaturation = Math.min(100, Math.max(90, Math.round(prevSigns.oxygenSaturation - (Math.random() * 2))));
            break;
          default:
            // For any other animations, use the original update logic
            newSigns.heartRate = Math.round(prevSigns.heartRate + (Math.random() - 0.5) * 2);
            newSigns.bloodPressure = {
              systolic: Math.round(prevSigns.bloodPressure.systolic + (Math.random() - 0.5) * 2),
              diastolic: Math.round(prevSigns.bloodPressure.diastolic + (Math.random() - 0.5) * 1.5)
            };
            newSigns.bodyTemperature = Math.round((prevSigns.bodyTemperature + (Math.random() - 0.5) * 0.1) * 10) / 10;
            newSigns.respiratoryRate = Math.round(prevSigns.respiratoryRate + (Math.random() - 0.5) * 1);
            newSigns.oxygenSaturation = Math.min(100, Math.max(90, Math.round(prevSigns.oxygenSaturation + (Math.random() - 0.5) * 1)));
        }

        newSigns.ecg = generateEcgData(); // Generate new ECG data

        return newSigns;
      });

      const currentTime = new Date().toISOString().substr(11, 8);

      setHeartRateData(prev => [...prev.slice(1), { time: currentTime, value: vitalSigns.heartRate }]);
      setBloodPressureData(prev => [...prev.slice(1), { 
        time: currentTime, 
        systolic: vitalSigns.bloodPressure.systolic, 
        diastolic: vitalSigns.bloodPressure.diastolic 
      }]);
      setBodyTemperatureData(prev => [...prev.slice(1), { time: currentTime, value: vitalSigns.bodyTemperature }]);
      setRespiratoryRateData(prev => [...prev.slice(1), { time: currentTime, value: vitalSigns.respiratoryRate }]);
      setOxygenSaturationData(prev => [...prev.slice(1), { time: currentTime, value: vitalSigns.oxygenSaturation }]);
      setEcgData(vitalSigns.ecg); // Update ECG data
    };

    const interval = setInterval(updateVitalSigns, 1000); // Update every second
    return () => clearInterval(interval);
  }, [vitalSigns, currentAnimation]);

  useEffect(() => {
    if (BABYLON.Engine.isSupported()) {
      const canvas = canvasRef.current;
      const engine = new BABYLON.Engine(canvas, true);

      const createScene = () => {
        const scene = new BABYLON.Scene(engine);
        const light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 3, 0), scene);
        const camera = new BABYLON.ArcRotateCamera("Camera", 0, Math.PI / 2, 0.1, BABYLON.Vector3.Zero(), scene);
        camera.attachControl(canvas, true);
        camera.lowerRadiusLimit = 3;
        camera.upperRadiusLimit = 10;

        // Load the GLB model
        SceneLoader.ImportMesh("", "/assets/", "wolf.glb", scene, (meshes, particleSystems, skeletons, animationGroups) => {
          const wolf = meshes[0];
          wolf.position = BABYLON.Vector3.Zero();
          
          if (skeletons && skeletons.length > 0) {
            setSkeleton(skeletons[0]);
          }

          if (animationGroups && animationGroups.length > 0) {
            setAnimationGroups(animationGroups);
            // Stop all animations initially
            animationGroups.forEach(ag => ag.stop());
          }

          console.log("Wolf model loaded successfully:", meshes);

          // Adjust camera to fit the model
          const boundingInfo = wolf.getHierarchyBoundingVectors();
          const size = boundingInfo.max.subtract(boundingInfo.min);
          const maxDimension = Math.max(size.x, size.y, size.z);
          camera.radius = maxDimension * 1.5; // Adjust this multiplier as needed
        }, null, (scene, message, exception) => {
          console.error("Error while loading wolf model:", message, exception);
        });

        return scene;
      };

      const scene = createScene();

      engine.runRenderLoop(() => {
        scene.render();
      });

      window.addEventListener("resize", () => {
        engine.resize();
      });

      return () => {
        scene.dispose();
        engine.dispose();
      };
    }
  }, []);

  const changeAnimation = () => {
    if (animationGroups.length === 0) return;

    // Stop current animation
    const currentAnimationGroup = animationGroups.find(ag => ag.name === currentAnimation);
    if (currentAnimationGroup) {
      currentAnimationGroup.stop();
    }

    // Get next animation
    const animationNames = animationGroups.map(ag => ag.name);
    const currentIndex = animationNames.indexOf(currentAnimation);
    const nextIndex = (currentIndex + 1) % animationNames.length;
    const nextAnimation = animationNames[nextIndex];

    // Start next animation
    const nextAnimationGroup = animationGroups.find(ag => ag.name === nextAnimation);
    if (nextAnimationGroup) {
      nextAnimationGroup.start(true);
    }

    setCurrentAnimation(nextAnimation);
  };

  const renderVitalSigns = () => {
    const tabs = [
      { key: 'ecg', label: 'ECG' }, // ECG tab moved to the first position
      { key: 'heartRate', label: 'Heart Rate' },
      { key: 'bloodPressure', label: 'Blood Pressure' },
      { key: 'bodyTemperature', label: 'Body Temperature' },
      { key: 'respiratoryRate', label: 'Respiratory Rate' },
      { key: 'oxygenSaturation', label: 'Oxygen Saturation' }
    ];

    const renderTabContent = () => {
      switch (selectedTab) {
        case 'ecg':
          return (
            <div>
              <div>ECG</div>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={ecgData}>
                  <XAxis dataKey="time" />
                  <YAxis domain={[-2, 2]} />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="value" stroke="#82ca9d" strokeWidth={2} dot={false} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          );
        case 'heartRate':
          return (
            <div>
              <div>Current Heart Rate: {vitalSigns.heartRate} bpm</div>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={heartRateData}>
                  <XAxis dataKey="time" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="value" stroke="#FF6B6B" strokeWidth={2} dot={false} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          );
        case 'bloodPressure':
          return (
            <div>
              <div>Current Blood Pressure: {vitalSigns.bloodPressure.systolic}/{vitalSigns.bloodPressure.diastolic} mmHg</div>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={bloodPressureData}>
                  <XAxis dataKey="time" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="systolic" stroke="#4ECDC4" strokeWidth={2} dot={false} />
                  <Line type="monotone" dataKey="diastolic" stroke="#45B7D1" strokeWidth={2} dot={false} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          );
        case 'bodyTemperature':
          return (
            <div>
              <div>Current Body Temperature: {vitalSigns.bodyTemperature.toFixed(1)} °C</div>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={bodyTemperatureData}>
                  <XAxis dataKey="time" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="value" stroke="#FF9FF3" strokeWidth={2} dot={false} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          );
        case 'respiratoryRate':
          return (
            <div>
              <div>Current Respiratory Rate: {vitalSigns.respiratoryRate} breaths/min</div>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={respiratoryRateData}>
                  <XAxis dataKey="time" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="value" stroke="#54A0FF" strokeWidth={2} dot={false} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          );
        case 'oxygenSaturation':
          return (
            <div>
              <div>Current Oxygen Saturation: {vitalSigns.oxygenSaturation}%</div>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={oxygenSaturationData}>
                  <XAxis dataKey="time" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="value" stroke="#5ED4F3" strokeWidth={2} dot={false} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          );
        default:
          return null;
      }
    };

    return (
      <div>
        <div style={{ display: 'flex', borderBottom: '1px solid #ccc' }}>
          {tabs.map(tab => (
            <button
              key={tab.key}
              onClick={() => setSelectedTab(tab.key)}
              style={{
                padding: '10px',
                backgroundColor: selectedTab === tab.key ? '#f0f0f0' : 'transparent',
                border: 'none',
                borderBottom: selectedTab === tab.key ? '2px solid #1890ff' : 'none',
                cursor: 'pointer'
              }}
            >
              {tab.label}
            </button>
          ))}
        </div>
        <div style={{ padding: '20px' }}>
          {renderTabContent()}
        </div>
      </div>
    );
  };

  const renderStatistics = () => {
    const heartRateStats = generateStatData(heartRateData);
    const bloodPressureStats = {
      systolic: generateStatData(bloodPressureData.map(item => ({ ...item, value: item.systolic }))),
      diastolic: generateStatData(bloodPressureData.map(item => ({ ...item, value: item.diastolic })))
    };
    const bodyTemperatureStats = generateStatData(bodyTemperatureData);
    const respiratoryRateStats = generateStatData(respiratoryRateData);
    const oxygenSaturationStats = generateStatData(oxygenSaturationData);

    const tabs = [
      { key: 'heartRate', label: 'Heart Rate' },
      { key: 'bloodPressure', label: 'Blood Pressure' },
      { key: 'bodyTemperature', label: 'Body Temperature' },
      { key: 'respiratoryRate', label: 'Respiratory Rate' },
      { key: 'oxygenSaturation', label: 'Oxygen Saturation' }
    ];

    const renderTabContent = () => {
      switch (selectedTab) {
        case 'heartRate':
          return (
            <div>
              <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Metric</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>Average Heart Rate</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>{heartRateStats.avg} bpm</td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>Slowest Heart Rate</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>{heartRateStats.min} bpm</td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>Fastest Heart Rate</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>{heartRateStats.max} bpm</td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>Heart Rate Variability Index</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>{((heartRateStats.max - heartRateStats.min) / heartRateStats.avg * 100).toFixed(2)}%</td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>Morning Pulse</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>{heartRateData[0].value} bpm</td>
                  </tr>
                </tbody>
              </table>
              <h3>Weekly Hourly Heart Rate</h3>
              {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, index) => (
                <React.Fragment key={day}>
                  <h4>{day}</h4>
                  <ResponsiveContainer width="100%" height={200}>
                    <ScatterChart
                      margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20,
                      }}
                    >
                      <CartesianGrid />
                      <XAxis type="number" dataKey="hour" name="Hour" domain={[0, 23]} />
                      <YAxis type="number" dataKey="avgBPM" name="Heart Rate" unit="bpm" />
                      <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                      <Scatter
                        name="Heart Rate"
                        data={weeklyHourlyHeartRate.filter(data => data.day === 6 - index)}
                        fill="#8884d8"
                        shape="circle"
                      />
                    </ScatterChart>
                  </ResponsiveContainer>
                </React.Fragment>
              ))}
            </div>
          );
        case 'bloodPressure':
          return (
            <div>
              <h3>Blood Pressure Statistics</h3>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={[
                  { type: 'Min', systolic: bloodPressureStats.systolic.min, diastolic: bloodPressureStats.diastolic.min },
                  { type: 'Max', systolic: bloodPressureStats.systolic.max, diastolic: bloodPressureStats.diastolic.max },
                  { type: 'Avg', systolic: bloodPressureStats.systolic.avg, diastolic: bloodPressureStats.diastolic.avg }
                ]}>
                  <XAxis dataKey="type" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="systolic" fill="#4ECDC4" name="Systolic">
                    <LabelList dataKey="systolic" position="top" />
                  </Bar>
                  <Bar dataKey="diastolic" fill="#FF6B6B" name="Diastolic">
                    <LabelList dataKey="diastolic" position="top" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <h3>Weekly Hourly Blood Pressure</h3>
              {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, index) => (
                <React.Fragment key={day}>
                  <h4>{day}</h4>
                  <ResponsiveContainer width="100%" height={200}>
                    <ScatterChart
                      margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20,
                      }}
                    >
                      <CartesianGrid />
                      <XAxis type="number" dataKey="hour" name="Hour" domain={[0, 23]} />
                      <YAxis type="number" domain={[60, 160]} name="Blood Pressure" unit="mmHg" />
                      <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                      <Legend />
                      <Scatter
                        name="Systolic"
                        data={weeklyHourlyBloodPressure.filter(data => data.day === 6 - index)}
                        dataKey="avgSystolic"
                        fill="#FF6B6B"
                        shape="circle"
                      />
                      <Scatter
                        name="Diastolic"
                        data={weeklyHourlyBloodPressure.filter(data => data.day === 6 - index)}
                        dataKey="avgDiastolic"
                        fill="#4ECDC4"
                        shape="triangle"
                      />
                    </ScatterChart>
                  </ResponsiveContainer>
                </React.Fragment>
              ))}
            </div>
          );
        case 'bodyTemperature':
          return (
            <div>
              <h3>Body Temperature Statistics</h3>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={[
                  { type: 'Min', value: bodyTemperatureStats.min },
                  { type: 'Max', value: bodyTemperatureStats.max },
                  { type: 'Avg', value: bodyTemperatureStats.avg }
                ]}>
                  <XAxis dataKey="type" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="value" fill="#FF9FF3" />
                </BarChart>
              </ResponsiveContainer>
              <h3>Weekly Hourly Body Temperature</h3>
              {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, index) => (
                <React.Fragment key={day}>
                  <h4>{day}</h4>
                  <ResponsiveContainer width="100%" height={200}>
                    <ScatterChart
                      margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20,
                      }}
                    >
                      <CartesianGrid />
                      <XAxis type="number" dataKey="hour" name="Hour" domain={[0, 23]} />
                      <YAxis type="number" dataKey="avgTemperature" name="Temperature" unit="°C" />
                      <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                      <Scatter
                        name="Body Temperature"
                        data={weeklyHourlyBodyTemperature.filter(data => data.day === 6 - index)}
                        fill="#FF9FF3"
                        shape="circle"
                      />
                    </ScatterChart>
                  </ResponsiveContainer>
                </React.Fragment>
              ))}
            </div>
          );
        case 'respiratoryRate':
          return (
            <div>
              <h3>Respiratory Rate Statistics</h3>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={[
                  { type: 'Min', value: respiratoryRateStats.min },
                  { type: 'Max', value: respiratoryRateStats.max },
                  { type: 'Avg', value: respiratoryRateStats.avg }
                ]}>
                  <XAxis dataKey="type" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="value" fill="#54A0FF" />
                </BarChart>
              </ResponsiveContainer>
              <h3>Weekly Hourly Respiratory Rate</h3>
              {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, index) => (
                <React.Fragment key={day}>
                  <h4>{day}</h4>
                  <ResponsiveContainer width="100%" height={200}>
                    <ScatterChart
                      margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20,
                      }}
                    >
                      <CartesianGrid />
                      <XAxis type="number" dataKey="hour" name="Hour" domain={[0, 23]} />
                      <YAxis type="number" dataKey="avgRespiratoryRate" name="Respiratory Rate" unit="breaths/min" />
                      <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                      <Scatter
                        name="Respiratory Rate"
                        data={weeklyHourlyRespiratoryRate.filter(data => data.day === 6 - index)}
                        fill="#54A0FF"
                        shape="circle"
                      />
                    </ScatterChart>
                  </ResponsiveContainer>
                </React.Fragment>
              ))}
            </div>
          );
        case 'oxygenSaturation':
          return (
            <div>
              <h3>Oxygen Saturation Statistics</h3>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={[
                  { type: 'Min', value: oxygenSaturationStats.min },
                  { type: 'Max', value: oxygenSaturationStats.max },
                  { type: 'Avg', value: oxygenSaturationStats.avg }
                ]}>
                  <XAxis dataKey="type" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="value" fill="#5ED4F3" />
                </BarChart>
              </ResponsiveContainer>
              <h3>Weekly Hourly Oxygen Saturation</h3>
              {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day, index) => (
                <React.Fragment key={day}>
                  <h4>{day}</h4>
                  <ResponsiveContainer width="100%" height={200}>
                    <ScatterChart
                      margin={{
                        top: 20,
                        right: 20,
                        bottom: 20,
                        left: 20,
                      }}
                    >
                      <CartesianGrid />
                      <XAxis type="number" dataKey="hour" name="Hour" domain={[0, 23]} />
                      <YAxis type="number" dataKey="avgOxygenSaturation" name="Oxygen Saturation" unit="%" domain={[90, 100]} />
                      <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                      <Scatter
                        name="Oxygen Saturation"
                        data={weeklyHourlyOxygenSaturation.filter(data => data.day === 6 - index)}
                        fill="#5ED4F3"
                        shape="circle"
                      >
                        {weeklyHourlyOxygenSaturation
                          .filter(data => data.day === 6 - index)
                          .map((entry, index) => (
                            <Cell key={`cell-${index}`} fill="#5ED4F3" />
                          ))}
                      </Scatter>
                    </ScatterChart>
                  </ResponsiveContainer>
                </React.Fragment>
              ))}
              {console.log('Weekly Hourly Oxygen Saturation Data:', weeklyHourlyOxygenSaturation)}
            </div>
          );
        default:
          return null;
      }
    };

    return (
      <div>
        <div style={{ display: 'flex', borderBottom: '1px solid #ccc' }}>
          {tabs.map(tab => (
            <button
              key={tab.key}
              onClick={() => setSelectedTab(tab.key)}
              style={{
                padding: '10px',
                backgroundColor: selectedTab === tab.key ? '#f0f0f0' : 'transparent',
                border: 'none',
                borderBottom: selectedTab === tab.key ? '2px solid #1890ff' : 'none',
                cursor: 'pointer'
              }}
            >
              {tab.label}
            </button>
          ))}
        </div>
        <div style={{ padding: '20px' }}>
          {renderTabContent()}
        </div>
      </div>
    );
  };

  const renderSettings = () => {
    return <div>Settings Content</div>;
  };

  const renderSleep = () => {
    const sleepData = [
      { name: 'Deep Sleep', value: 25 },
      { name: 'Light Sleep', value: 45 },
      { name: 'REM Sleep', value: 20 },
      { name: 'Awake', value: 10 },
    ];

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const sleepMetrics = {
      totalSleepTime: '7h 30m',
      sleepEfficiency: '85%',
      sleepLatency: '15m',
      wakeAfterSleepOnset: '30m',
      remSleep: '20%',
      remSleepDuration: '1h 30m',
      deepSleep: '25%',
      deepSleepDuration: '1h 52m',
      lightSleep: '45%',
      lightSleepDuration: '3h 22m',
      awakeTime: '10%',
      awakeTimeDuration: '45m'
    };

    const tabs = [
      { key: 'sleepMetrics', label: 'Sleep Metrics' },
      { key: 'sleepStages', label: 'Sleep Stages' },
      { key: 'psg', label: 'PSG' },
      
    ];

    const renderTabContent = () => {
      switch (selectedTab) {
        case 'sleepStages':
          return (
            <div>
              <h2>Sleep Stages</h2>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={sleepData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {sleepData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>

              <ResponsiveContainer width="100%" height={300}>
                <AreaChart
                  data={dailySleepDuration.map((item, index) => ({
                    ...item,
                    light: lightSleepDuration[index].lightDuration,
                    deep: deepSleepDuration[index].deepDuration,
                    rem: remSleepDuration[index].remDuration
                  }))}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="day" tickFormatter={(day) => ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][day]} />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Area type="monotone" dataKey="light" stackId="1" stroke="#8884d8" fill="#8884d8" name="Light Sleep" />
                  <Area type="monotone" dataKey="deep" stackId="1" stroke="#82ca9d" fill="#82ca9d" name="Deep Sleep" />
                  <Area type="monotone" dataKey="rem" stackId="1" stroke="#ffc658" fill="#ffc658" name="REM Sleep" />
                </AreaChart>
              </ResponsiveContainer>
              <div style={{ textAlign: 'center', fontSize: '16px', marginTop: '5px' }}>
                Daily Sleep Duration
              </div>

              <h2>Sleep Wave Patterns</h2>
              <ResponsiveContainer width="100%" height={200}>
                <LineChart
                  data={slowWaveData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="time" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="value" stroke="#8884d8" name="Slow Wave" dot={false} />
                </LineChart>
              </ResponsiveContainer>

              <ResponsiveContainer width="100%" height={200}>
                <LineChart
                  data={kComplexData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="time" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="value" stroke="#82ca9d" name="K-Complex" dot={false} />
                </LineChart>
              </ResponsiveContainer>

              <ResponsiveContainer width="100%" height={200}>
                <LineChart
                  data={sleepSpindlesData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="time" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="value" stroke="#ffc658" name="Sleep Spindles" dot={false} />
                </LineChart>
              </ResponsiveContainer>

              <ResponsiveContainer width="100%" height={200}>
                <LineChart
                  data={sawtoothWaveData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="time" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="value" stroke="#ff7300" name="Sawtooth Wave" dot={false} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          );
        case 'psg':
          return (
            <div>
              <h2>EEG Data</h2>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={generateEegData()}>
                  <XAxis dataKey="time" />
                  <YAxis domain={[-2, 2]} />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2} dot={false} />
                </LineChart>
              </ResponsiveContainer>
              <h2>EOG Data</h2>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={generateEogData()}>
                  <XAxis dataKey="time" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="value" stroke="#82ca9d" strokeWidth={2} dot={false} />
                </LineChart>
              </ResponsiveContainer>

              <h2>EMG Data</h2>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={generateEmgData()}>
                  <XAxis dataKey="time" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="value" stroke="#ffc658" strokeWidth={2} dot={false} />
                </LineChart>
              </ResponsiveContainer>
            </div>
          );
        case 'sleepMetrics':
          return (
            <div>
              <h2>Sleep Metrics</h2>
              <ResponsiveContainer width="100%" height={300}>
                <AreaChart
                  data={weeklySleepEfficiency}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="day" tickFormatter={(day) => ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][day]} />
                  <YAxis />
                  <Tooltip />
                  <Area type="monotone" dataKey="sleepEfficiency" stroke="#8884d8" fill="#8884d8" />
                </AreaChart>
              </ResponsiveContainer>
              <div style={{ textAlign: 'center', fontSize: '16px', marginTop: '5px' }}>
                Weekly Sleep Efficiency
              </div>
              <ResponsiveContainer width="100%" height={300}>
                <AreaChart
                  data={dailySleepDuration}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 20,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="day" tickFormatter={(day) => ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][day]} />
                  <YAxis />
                  <Tooltip />
                  <Area type="monotone" dataKey="sleepDuration" stroke="#82ca9d" fill="#82ca9d" />
                </AreaChart>
              </ResponsiveContainer>
              <div style={{ textAlign: 'center', fontSize: '16px', marginTop: '5px' }}>
                Weekly Sleep Duration
              </div>
           
              <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Metric</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(sleepMetrics).map(([key, value]) => (
                    <tr key={key}>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{key}</td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          );
        default:
          return null;
      }
    };

    return (
      <div>
        <div style={{ display: 'flex', borderBottom: '1px solid #ccc' }}>
          {tabs.map(tab => (
            <button
              key={tab.key}
              onClick={() => setSelectedTab(tab.key)}
              style={{
                padding: '10px',
                backgroundColor: selectedTab === tab.key ? '#f0f0f0' : 'transparent',
                border: 'none',
                borderBottom: selectedTab === tab.key ? '2px solid #1890ff' : 'none',
                cursor: 'pointer'
              }}
            >
              {tab.label}
            </button>
          ))}
        </div>
        <div style={{ padding: '20px' }}>
          {renderTabContent()}
        </div>
      </div>
    );
  };

  const renderInfection = () => {
    const tabs = [
      { key: 'skinLesion', label: 'Skin Lesion' },
      { key: 'parasiteInfestation', label: 'Parasite Infestation' },
      // Add more tabs here in the future
    ];

    const renderTabContent = () => {
      switch (selectedTab) {
        case 'skinLesion':
          return (
            <div>
              <h2>Skin Lesion Data</h2>
              <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Area</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Hair Loss (%)</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Inflammation (0-10)</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Scabs (0-10)</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px', textAlign: 'left' }}>Ulcers</th>
                  </tr>
                </thead>
                <tbody>
                  {skinLesionData.map((item, index) => (
                    <tr key={index}>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.area}</td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.hairLoss.toFixed(1)}</td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.inflammation.toFixed(1)}</td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.scabs.toFixed(1)}</td>
                      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.ulcers.toFixed(1)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <p style={{ fontSize: '12px', color: '#666', marginTop: '10px', fontStyle: 'italic' }}>
                Note: This data represents the trend changes over the past week. The data has been accelerated for display purposes.
              </p>
              <h3>3D Visualization of Skin Lesion Data</h3>
              <ResponsiveContainer width="100%" height={400}>
                <ScatterChart
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid />
                  <XAxis type="number" dataKey="hairLoss" name="Hair Loss" unit="%" />
                  <YAxis type="number" dataKey="inflammation" name="Inflammation" unit="/10" />
                  <ZAxis type="number" dataKey="scabs" name="Scabs" unit="/10" range={[0, 100]} />
                  <Tooltip 
                    cursor={{ strokeDasharray: '3 3' }}
                    content={({ payload }) => {
                      if (payload && payload.length) {
                        const data = payload[0].payload;
                        return (
                          <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
                            <p>{`Area: ${data.area}`}</p>
                            <p>{`Hair Loss: ${data.hairLoss.toFixed(1)}%`}</p>
                            <p>{`Inflammation: ${data.inflammation.toFixed(1)}/10`}</p>
                            <p>{`Scabs: ${data.scabs.toFixed(1)}/10`}</p>
                            <p>{`Ulcers: ${data.ulcers.toFixed(1)}`}</p>
                          </div>
                        );
                      }
                      return null;
                    }}
                  />
                  <Legend />
                  <Scatter name="Skin Lesion" data={skinLesionData} fill="#8884d8">
                    {skinLesionData.map((entry, index) => {
                      const hue = (index * 60) % 360; // This will give distinct colors for up to 6 areas
                      return (
                        <Cell 
                          key={`cell-${index}`} 
                          fill={`hsl(${hue}, 70%, ${50 + entry.ulcers * 5}%)`} 
                        />
                      );
                    })}
                  </Scatter>
                </ScatterChart>
              </ResponsiveContainer>
              <div style={{ marginTop: '10px', fontSize: '14px' }}>
                <p>Bubble colors represent different areas:</p>
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                  {skinLesionData.map((item, index) => {
                    const hue = (index * 60) % 360;
                    return (
                      <li key={index} style={{ display: 'inline-block', marginRight: '20px' }}>
                        <span style={{ 
                          display: 'inline-block', 
                          width: '12px', 
                          height: '12px', 
                          backgroundColor: `hsl(${hue}, 70%, ${50 + item.ulcers * 5}%)`,
                          marginRight: '5px'
                        }}></span>
                        {item.area}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div style={{ textAlign: 'center',  color: '#666',marginTop: '10px', fontSize: '12px' }}>
                Note: X-axis represents hair loss, Y-axis represents inflammation, Z-axis (bubble size) represents scabs severity, and color intensity represents ulcers severity
              </div>
            </div>
          );
        case 'parasiteInfestation':
          return (
            <div>
              <h3>Parasite Infestation</h3>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={parasiteData}
                  margin={{
                    top: 20, right: 30, left: 20, bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="parasite" />
                  <YAxis />
                  <Tooltip content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      const data = payload[0].payload;
                      return (
                        <div style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                          <p><strong>{data.parasite}</strong></p>
                          <p>Count: {data.count}</p>
                          <p>Severity: {data.severity}</p>
                          <p>Location: {data.location}</p>
                        </div>
                      );
                    }
                    return null;
                  }} />
                  <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
                  <ReferenceLine y={0} stroke="#000" />
                  <Brush dataKey="parasite" height={30} stroke="#8884d8" />
                  <Bar dataKey="count" fill="#8884d8">
                    <LabelList dataKey="count" position="top" />
                  </Bar>
                  <Bar dataKey="severity" fill="#82ca9d">
                    <LabelList dataKey="severity" position="top" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
              <div style={{ marginTop: '20px' }}>
                <h4 style={{ fontSize: '14px' }}>Parasite Details:</h4>
                <ul style={{ fontSize: '12px' }}>
                  {parasiteData.map((item, index) => (
                    <li key={index}>
                      <strong>{item.parasite}:</strong> {item.description}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          );
        default:
          return null;
      }
    };

    return (
      <div>
        <div style={{ display: 'flex', borderBottom: '1px solid #ccc' }}>
          {tabs.map(tab => (
            <button
              key={tab.key}
              onClick={() => setSelectedTab(tab.key)}
              style={{
                padding: '10px',
                backgroundColor: selectedTab === tab.key ? '#f0f0f0' : 'transparent',
                border: 'none',
                borderBottom: selectedTab === tab.key ? '2px solid #1890ff' : 'none',
                cursor: 'pointer'
              }}
            >
              {tab.label}
            </button>
          ))}
        </div>
        <div style={{ padding: '20px' }}>
          {renderTabContent()}
        </div>
      </div>
    );
  };
  const renderOverall = () => {
    return (
      <div>
        <h2>Overall Wolf Health</h2>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          <div style={{ width: '100%', marginBottom: '20px' }}>
            <h3>Wolf Health Radar</h3>
            <ResponsiveContainer width="100%" height={400}>
              <RadarChart cx="50%" cy="50%" outerRadius="70%" data={[
                {
                  subject: 'Heart Rate',
                  A: (vitalSigns.heartRate - 60) / (120 - 60) * 100, // Normalize to 0-100 scale
                  fullMark: 100,
                },
                {
                  subject: 'Blood Pressure',
                  A: ((vitalSigns.bloodPressure.systolic - 90) / (140 - 90) + (vitalSigns.bloodPressure.diastolic - 60) / (90 - 60)) / 2 * 100,
                  fullMark: 100,
                },
                {
                  subject: 'Body Temperature',
                  A: (vitalSigns.bodyTemperature - 37) / (39 - 37) * 100,
                  fullMark: 100,
                },
                {
                  subject: 'Respiratory Rate',
                  A: (vitalSigns.respiratoryRate - 10) / (30 - 10) * 100,
                  fullMark: 100,
                },
                {
                  subject: 'Oxygen Saturation',
                  A: (vitalSigns.oxygenSaturation - 90) / (100 - 90) * 100,
                  fullMark: 100,
                },
                {
                  subject: 'Skin Lesions',
                  A: 100 - (skinLesionData.reduce((sum, item) => sum + item.hairLoss + item.inflammation + item.scabs + item.ulcers, 0) / (skinLesionData.length * 25) * 100), // Inverse of average lesion severity
                  fullMark: 100,
                },
                {
                  subject: 'Parasite Infestation',
                  A: 100 - (parasiteData.reduce((sum, item) => sum + item.count, 0) / (parasiteData.length * 100) * 100), // Inverse of average parasite count
                  fullMark: 100,
                },
              ]}>
                <PolarGrid />
                <PolarAngleAxis dataKey="subject" />
                <PolarRadiusAxis angle={30} domain={[0, 100]} />
                <Radar name="Wolf Health" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
              </RadarChart>
            </ResponsiveContainer>
          </div>
          <div style={{ width: '48%', marginBottom: '20px' }}>
            <h3>Vital Signs Summary</h3>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={{ padding: '8px', border: '1px solid #ddd' }}>Metric</th>
                  <th style={{ padding: '8px', border: '1px solid #ddd' }}>Value</th>
                  <th style={{ padding: '8px', border: '1px solid #ddd' }}>Normalized Score</th>
                  <th style={{ padding: '8px', border: '1px solid #ddd' }}>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>Heart Rate</td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>{vitalSigns.heartRate} bpm</td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>{((vitalSigns.heartRate - 60) / (120 - 60) * 100).toFixed(1)}</td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>{((vitalSigns.heartRate - 60) / (120 - 60) * 100) > 80 ? 'Bad' : 'Good'}</td>
                </tr>
                <tr>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>Blood Pressure</td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>{vitalSigns.bloodPressure.systolic}/{vitalSigns.bloodPressure.diastolic} mmHg</td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>{(((vitalSigns.bloodPressure.systolic - 90) / (140 - 90) + (vitalSigns.bloodPressure.diastolic - 60) / (90 - 60)) / 2 * 100).toFixed(1)}</td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>{(((vitalSigns.bloodPressure.systolic - 90) / (140 - 90) + (vitalSigns.bloodPressure.diastolic - 60) / (90 - 60)) / 2 * 100) > 80 ? 'Bad' : 'Good'}</td>
                </tr>
                <tr>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>Body Temperature</td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>{vitalSigns.bodyTemperature.toFixed(1)}°C</td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>{((vitalSigns.bodyTemperature - 37) / (39 - 37) * 100).toFixed(1)}</td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>{((vitalSigns.bodyTemperature - 37) / (39 - 37) * 100) > 80 ? 'Bad' : 'Good'}</td>
                </tr>
                <tr>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>Respiratory Rate</td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>{vitalSigns.respiratoryRate} breaths/min</td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>{((vitalSigns.respiratoryRate - 10) / (30 - 10) * 100).toFixed(1)}</td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>{((vitalSigns.respiratoryRate - 10) / (30 - 10) * 100) > 80 ? 'Bad' : 'Good'}</td>
                </tr>
                <tr>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>Oxygen Saturation</td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>{vitalSigns.oxygenSaturation}%</td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>{((vitalSigns.oxygenSaturation - 90) / (100 - 90) * 100).toFixed(1)}</td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>{((vitalSigns.oxygenSaturation - 90) / (100 - 90) * 100) > 80 ? 'Bad' : 'Good'}</td>
                </tr>
                <tr>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>Skin Lesions</td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>{(skinLesionData.reduce((sum, item) => sum + item.hairLoss + item.inflammation + item.scabs + item.ulcers, 0) / (skinLesionData.length * 25) * 100).toFixed(1)}%</td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>{(Math.max(41, 100 - (skinLesionData.reduce((sum, item) => sum + item.hairLoss + item.inflammation + item.scabs + item.ulcers, 0) / (skinLesionData.length * 25) * 100))).toFixed(1)}</td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>{(100 - (skinLesionData.reduce((sum, item) => sum + item.hairLoss + item.inflammation + item.scabs + item.ulcers, 0) / (skinLesionData.length * 25) * 100)) > 40 ? 'Not Bad' : 'Bad'}</td>
                </tr>
                <tr>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>Parasite Infestation</td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>{(parasiteData.reduce((sum, item) => sum + item.count, 0) / (parasiteData.length * 100) * 100).toFixed(1)}%</td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>{(100 - (parasiteData.reduce((sum, item) => sum + item.count, 0) / (parasiteData.length * 100) * 100)).toFixed(1)}</td>
                  <td style={{ padding: '8px', border: '1px solid #ddd' }}>{(100 - (parasiteData.reduce((sum, item) => sum + item.count, 0) / (parasiteData.length * 100) * 100)) > 80 ? 'Good' : 'Bad'}</td>
                </tr>
              </tbody>
            </table>
          </div>
      
        </div>
        <div>
          <h3>Recent Activity</h3>
          <p>Current Animation: {currentAnimation}</p>
        </div>
      </div>
    );
  };

  const renderDataPrediction = () => {

    const tabs = [
      { key: 'prediction', label: 'Prediction' },
      { key: 'risks', label: 'Risks' },
    ];



    const heartRatePrediction = generatePredictionData(vitalSigns.heartRate, 5, 7);
    const systolicPrediction = generatePredictionData(vitalSigns.bloodPressure.systolic, 8, 7);
    const diastolicPrediction = generatePredictionData(vitalSigns.bloodPressure.diastolic, 6, 7);
    const temperaturePrediction = generatePredictionData(vitalSigns.bodyTemperature, 0.5, 7);
    const respiratoryRatePrediction = generatePredictionData(vitalSigns.respiratoryRate, 2, 7);
    const oxygenSaturationPrediction = generatePredictionData(vitalSigns.oxygenSaturation, 1, 7);

    const renderPredictionCharts = () => {
      // Generate prediction data only once
 

      return (
        <div>
          <h3>One Week Prediction</h3>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
            <div style={{ width: '48%', marginBottom: '20px' }}>
              <h4>Heart Rate Prediction</h4>
              <ResponsiveContainer width="100%" height={200}>
                <LineChart data={predictionData.heartRate}>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="value" stroke="#8884d8" />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div style={{ width: '48%', marginBottom: '20px' }}>
              <h4>Blood Pressure Prediction</h4>
              <ResponsiveContainer width="100%" height={200}>
                <LineChart>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" data={predictionData.systolic} dataKey="value" stroke="#8884d8" name="Systolic" />
                  <Line type="monotone" data={predictionData.diastolic} dataKey="value" stroke="#82ca9d" name="Diastolic" />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div style={{ width: '48%', marginBottom: '20px' }}>
              <h4>Body Temperature Prediction</h4>
              <ResponsiveContainer width="100%" height={200}>
                <LineChart data={predictionData.temperature}>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="value" stroke="#8884d8" />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div style={{ width: '48%', marginBottom: '20px' }}>
              <h4>Respiratory Rate Prediction</h4>
              <ResponsiveContainer width="100%" height={200}>
                <LineChart data={predictionData.respiratoryRate}>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="value" stroke="#8884d8" />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div style={{ width: '48%', marginBottom: '20px' }}>
              <h4>Oxygen Saturation Prediction</h4>
              <ResponsiveContainer width="100%" height={200}>
                <LineChart data={predictionData.oxygenSaturation}>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="value" stroke="#8884d8" />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div style={{ width: '100%', marginBottom: '20px' }}>
              <h4>Sleep Prediction</h4>
              <ResponsiveContainer width="100%" height={300}>
                <AreaChart data={predictionData.sleep.deepSleep.map((item, index) => ({
                  day: item.day,
                  deep: item.value,
                  rem: predictionData.sleep.remSleep[index].value,
                  light: predictionData.sleep.lightSleep[index].value
                }))}>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Tooltip />
                  <Area type="monotone" dataKey="deep" stackId="1" stroke="#8884d8" fill="#8884d8" />
                  <Area type="monotone" dataKey="rem" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                  <Area type="monotone" dataKey="light" stackId="1" stroke="#ffc658" fill="#ffc658" />
                </AreaChart>
              </ResponsiveContainer>
            <div style={{ width: '100%', marginBottom: '20px' }}>
              <h4>Total Sleep Time Prediction</h4>
              <ResponsiveContainer width="100%" height={200}>
                <LineChart data={predictionData.sleep.totalSleepTime}>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="value" stroke="#8884d8" />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div style={{ width: '100%', marginBottom: '20px' }}>
              <h4>Sleep Efficiency Prediction</h4>
              <ResponsiveContainer width="100%" height={200}>
                <LineChart data={predictionData.sleep.sleepEfficiency}>
                  <XAxis dataKey="day" />
                  <YAxis />
                  <Tooltip />
                  <Line type="monotone" dataKey="value" stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
            </div>
            </div>
          </div>
        </div>
      );
    };

    const renderRisks = () => (
      <div>
        <h3>Potential Risks</h3>
        <div>
          <p style={{ fontSize: '0.9em' }}>Please note that these are predictions based on current trends. Consult with a veterinarian for a comprehensive health assessment.</p>
        <h4>Risk Assessment Visualization</h4>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {/* Vital Signs Risk Matrix */}
          <div style={{ width: '100%', marginBottom: '20px' }}>
            <h5>Vital Signs Risk Matrix</h5>
            <ResponsiveContainer width="100%" height={300}>
              <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                <CartesianGrid />
                <XAxis type="number" dataKey="impact" name="Impact" unit="%" />
                <YAxis type="number" dataKey="likelihood" name="Likelihood" unit="%" />
                <ZAxis type="number" dataKey="value" range={[50, 500]} name="Risk Value" />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                <Legend />
                <Scatter name="Heart Rate" data={[{ impact: 72, likelihood: 58, value: 420 }]} fill="#FF5733" />
                <Scatter name="Blood Pressure" data={[{ impact: 45, likelihood: 62, value: 380 }]} fill="#33FF57" />
                <Scatter name="Body Temperature" data={[{ impact: 55, likelihood: 40, value: 340 }]} fill="#3357FF" />
                <Scatter name="Respiratory Rate" data={[{ impact: 38, likelihood: 52, value: 290 }]} fill="#FF33F1" />
                <Scatter name="Oxygen Saturation" data={[{ impact: 60, likelihood: 35, value: 310 }]} fill="#33FFF1" />
              </ScatterChart>
            </ResponsiveContainer>
          </div>

          <div style={{ width: '100%', marginBottom: '20px' }}>
            <h5>Sleep Risk Matrix</h5>
            <ResponsiveContainer width="100%" height={300}>
              <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                <CartesianGrid />
                <XAxis type="number" dataKey="impact" name="Impact" unit="%" />
                <YAxis type="number" dataKey="likelihood" name="Likelihood" unit="%" />
                <ZAxis type="number" dataKey="value" range={[50, 500]} name="Risk Value" />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                <Legend />
                <Scatter name="Sleep Efficiency" data={[{ impact: 50, likelihood: 45, value: 425 }]} fill="#FFA500" />
                <Scatter name="Light Sleep" data={[{ impact: 38, likelihood: 52, value: 390 }]} fill="#800080" />
                <Scatter name="REM Sleep" data={[{ impact: 72, likelihood: 41, value: 310 }]} fill="#008080" />
                <Scatter name="Deep Sleep" data={[{ impact: 55, likelihood: 29, value: 280 }]} fill="#FF1493" />
              </ScatterChart>
            </ResponsiveContainer>
          </div>

          <div style={{ width: '100%', marginBottom: '20px' }}>
            <h5>Infection Risk Matrix</h5>
            <ResponsiveContainer width="100%" height={300}>
              <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                <CartesianGrid />
                <XAxis type="number" dataKey="impact" name="Impact" unit="%" />
                <YAxis type="number" dataKey="likelihood" name="Likelihood" unit="%" />
                <ZAxis type="number" dataKey="value" range={[50, 500]} name="Risk Value" />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                <Legend />
                <Scatter name="Skin Lesions" data={[{ impact: 50, likelihood: 45, value: 450 }]} fill="#FF4500" />
                <Scatter name="Parasites" data={[{ impact: 40, likelihood: 35, value: 350 }]} fill="#32CD32" />
              </ScatterChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div style={{ marginTop: '20px', fontSize: '14px', color: '#666' }}>
          <p>Note: The scatter charts above represent risk matrices for various health indicators.</p>
          <ul>
            <li>X-axis: Impact - represents the potential severity of the health issue</li>
            <li>Y-axis: Likelihood - represents the probability of the health issue occurring</li>
            <li>Bubble size: Risk Value - larger bubbles indicate higher overall risk</li>
          </ul>
          <p>Higher values on either axis or larger bubble sizes indicate higher risk levels. Pay special attention to data points in the upper-right quadrant, as they represent high-impact, high-likelihood risks.</p>
          <p>For example, in the Infection Risk Matrix, Skin Lesions show a relatively high impact and likelihood, indicating a significant risk that requires prompt attention.</p>
        </div>
        </div>
      </div>
    );

    return (
      <div>
        <div style={{ display: 'flex', borderBottom: '1px solid #ccc' }}>
          {tabs.map(tab => (
            <button
              key={tab.key}
              onClick={() => setSelectedTab(tab.key)}
              style={{
                padding: '10px',
                backgroundColor: selectedTab === tab.key ? '#f0f0f0' : 'transparent',
                border: 'none',
                borderBottom: selectedTab === tab.key ? '2px solid #1890ff' : 'none',
                cursor: 'pointer'
              }}
            >
              {tab.label}
            </button>
          ))}
        </div>
        <div style={{ padding: '20px' }}>
          {selectedTab === 'prediction' ? renderPredictionCharts() : renderRisks()}
        </div>
      </div>
    );
  };

  
  const renderDrawerContent = () => {
    switch (selectedMenu) {
      case 'overall':
        return renderOverall();
      case 'vitalSigns':
        return renderVitalSigns();
      case 'statistics':
        return renderStatistics();
      case 'sleep':
        return renderSleep();
      case 'settings':
        return renderSettings();
      case 'bacterialInfection':
        return renderInfection();
      case 'dataPrediction':
        return renderDataPrediction();
      default:
        return null;
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      <canvas ref={canvasRef} style={{ width: '100%', height: '100%' }} />
      <button
        onClick={() => {
          if (animationGroups.length > 0) {
            if (currentAnimation === 'Stopped') {
              // Start the animation
              const lastAnimation = animationGroups.find(ag => ag.name === lastPlayedAnimation);
              if (lastAnimation) {
                lastAnimation.start(true);
                setCurrentAnimation(lastPlayedAnimation);
              } else {
                // If no last animation, start the first one
                animationGroups[0].start(true);
                setCurrentAnimation(animationGroups[0].name);
              }
            } else {
              // Pause the animation
              animationGroups.forEach(ag => ag.stop());
              setLastPlayedAnimation(currentAnimation);
              setCurrentAnimation('Stopped');
            }
          }
        }}
        style={{
          position: 'absolute',
          top: '10px',
          right: '120px',
          padding: '10px',
          backgroundColor: currentAnimation === 'Stopped' ? '#4CAF50' : '#f44336',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer'
        }}
      >
        {currentAnimation === 'Stopped' ? 'Start' : 'Pause'}
      </button>
      <button
        onClick={changeAnimation}
        title={`Current state: ${currentAnimation}. Click to change to the next state.`}
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          padding: '10px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer'
        }}
      >
        {currentAnimation}
      </button>
      {isDrawerOpen && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '600px',
            height: '100%',
            backgroundColor: 'white',
            boxShadow: '-2px 0 5px rgba(0,0,0,0.1)',
            transition: 'transform 0.3s ease-in-out',
            transform: isDrawerOpen ? 'translateX(0)' : 'translateX(100%)',
          }}
        >
          <button
            onClick={() => setIsDrawerOpen(false)}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              padding: '5px 10px',
              backgroundColor: '#f44336',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              zIndex: 1000
            }}
          >
            Close
          </button>
          <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ width: '120px', height: '100%', borderRight: '1px solid #ccc', padding: '20px' }}>
              <button
                onClick={() => {setSelectedMenu('vitalSigns');
                  setSelectedTab('ecg');
                }}e
                style={{
                  display: 'block',
                  width: '100%',
                  padding: '10px',
                  marginBottom: '10px',
                  backgroundColor: selectedMenu === 'vitalSigns' ? '#f0f0f0' : 'transparent',
                  border: 'none',
                  textAlign: 'left',
                  cursor: 'pointer'
                }}
              >
                Vital Signs
              </button>
              <button
                onClick={() => {setSelectedMenu('statistics');
                  setSelectedTab('heartRate');
                }}
                style={{
                  display: 'block',
                  width: '100%',
                  padding: '10px',
                  marginBottom: '10px',
                  backgroundColor: selectedMenu === 'statistics' ? '#f0f0f0' : 'transparent',
                  border: 'none',
                  textAlign: 'left',
                  cursor: 'pointer'
                }}
              >
                Statistics
              </button>
              <button
                onClick={() =>{ setSelectedMenu('sleep');
                  setSelectedTab('sleepMetrics');}
                }
                style={{
                  display: 'block',
                  width: '100%',
                  padding: '10px',
                  marginBottom: '10px',
                  backgroundColor: selectedMenu === 'sleep' ? '#f0f0f0' : 'transparent',
                  border: 'none',
                  textAlign: 'left',
                  cursor: 'pointer'
                }}
              >
                Sleep
              </button>
              <button
                onClick={() =>{ setSelectedMenu('bacterialInfection');
                  setSelectedTab('skinLesion');}
                }
                style={{
                  display: 'block',
                  width: '100%',
                  padding: '10px',
                  marginBottom: '10px',
                  backgroundColor: selectedMenu === 'bacterialInfection' ? '#f0f0f0' : 'transparent',
                  border: 'none',
                  textAlign: 'left',
                  cursor: 'pointer'
                }}
              >
                Infection
              </button>
              <button
                onClick={() => {
                  setSelectedMenu('overall');
                  setSelectedTab('overallMetrics');
                }}
                style={{
                  display: 'block',
                  width: '100%',
                  padding: '10px',
                  marginBottom: '10px',
                  backgroundColor: selectedMenu === 'overall' ? '#f0f0f0' : 'transparent',
                  border: 'none',
                  textAlign: 'left',
                  cursor: 'pointer'
                }}
              >
                Overall
              </button>
              <button
                onClick={() => {
                  setSelectedMenu('dataPrediction');
                  setSelectedTab('prediction');
                }}
                style={{
                  display: 'block',
                  width: '100%',
                  padding: '10px',
                  marginBottom: '10px',
                  backgroundColor: selectedMenu === 'dataPrediction' ? '#f0f0f0' : 'transparent',
                  border: 'none',
                  textAlign: 'left',
                  cursor: 'pointer'
                }}
              >
                Prediction & Risks
              </button>
            </div>
            <div style={{ flex: 1, padding: '20px', overflowY: 'auto' }}>
              {renderDrawerContent()}
            </div>
          </div>
        </div>
      )}
      {!isDrawerOpen && (
        <button
          onClick={() => setIsDrawerOpen(true)}
          style={{
            position: 'absolute',
            top: '60px',
            right: '10px',
            padding: '10px',
            backgroundColor: '#2196F3',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer'
          }}
        >
          Medical Dashboard
        </button>
      )}
      <div style={{
        position: 'absolute',
        bottom: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        fontSize: '12px',
        color: 'white'
      }}>
        Created by Yi Wang
      </div>
    </div>
  );
};

export default Wolf;